<template>
    <div @click="details" class="section-box" style="box-shadow: none; border: 1px solid #dbdfe5;">
        <h3>{{ activo.NoActivo }}</h3>
        <p>{{ activo.Descripcion }}</p>
        <!-- <p>&nbsp;</p> -->
        <template v-if="activo.Resguardante">
            <p style="margin: 5px 0; font-size: 0.9em;">Este activo es resguardado por</p>
            <Resguardante style="margin-bottom: 5px !important;" :flat="true" :user="activo.Resguardante" :show-tags="false"/>
            <p style="font-size: 0.9em;"><span style="font-weight: bold;">Fecha de resguardo: </span> 22/Agosto/2019 09:45 AM</p>
        </template>
        <template v-else>
            <Tag type="dot" color="error">Sin resguardante</Tag>
        </template>
    </div>
</template>

<script>
    import Resguardante from "./../../Resguardantes/components/ResguardanteR"

    export default {
        props: {
            activo: { required: true, type: [Object, Array] },
        },
        methods: {
            details(){
                this.$router.push(`/activo/${this.activo.NoActivo}`)
                window.EventBus.$emit("search-view", false)
            }
        },
        components: {
            Resguardante,
        }
    }
</script>