<template>
    <div>
        <template v-if="isLoading">
            <spinnerS/>
        </template>
        <template v-else>
            <Carousel v-if="autorizacion.Activo.Imagenes != []" v-model="carousel">
                <CarouselItem v-for="image, i in autorizacion.Activo.Imagenes" :key="i">
                    <div class="carousel-item">
                        <el-image style="height: 80%; position: relative; left: 25%;" :src="image" fit="contain"/>
                    </div>
                </CarouselItem>
            </Carousel>
            <br>
            <h3 style="font-size: 15px;">No. Activo {{ autorizacion.Activo.NoActivo }}</h3>
            <p style="font-size: 15px;">{{ autorizacion.Activo.Descripcion }}</p>
            <br>
            <Resguardante style="margin-bottom: 10px !important;" title="Origen" :flat="true" :user="autorizacion.Origen" :showTags="false"/>
            <Resguardante style="margin-bottom: 10px !important;" title="Destino" :flat="true" :user="autorizacion.Destino" :showTags="false"/>
            <br>
            <Row style="font-size: 15px;">
                <Col span="24">
                    <p> <span style="font-weight: bold;">No. Serie:</span> {{ autorizacion.Activo.NoSerie }}</p>
                </Col>
                <p>&nbsp;</p>
                <Col span="12">
                    <p style="font-weight: bold; margin-bottom: 3px;">Fecha de Resguardo</p>
                <p>{{ autorizacion.Activo.FechaResguardo | moment("DD/MMMM/YYYY") }}</p>
                </Col>
                <Col span="12">
                    <p style="font-weight: bold; margin-bottom: 3px;">Fecha de Adquisición</p>
                    <p>{{ autorizacion.Activo.FechaAdquisicion | moment("DD/MMMM/YYYY") }}</p>
                </Col>
                <p>&nbsp;</p>
                <Col span="12">
                    <p style="font-weight: bold; margin-bottom: 3px;">Centro de costos</p>
                    <p>{{ autorizacion.Activo.CentroCosto }}</p>                
                </Col>
                <Col span="12">
                    <p style="font-weight: bold; margin-bottom: 3px;">Tipo de costo</p>
                    <p>{{ autorizacion.Activo.TipoCosto }}</p>                
                </Col>
                <p>&nbsp;</p>
            </Row>
            <br>
            <Row>
                <Col span="12">
                    <iButton @click="confirmReject" long type="error" icon="md-close-circle" style="font-size: 13px;">Rechazar</iButton>
                </Col>
                <Col span="12">
                    <iButton @click="confirmAuth" long style="margin: 0 2px; font-size: 13px;" type="success" icon="md-checkmark" >Autorizar</iButton>
                </Col>
            </Row>
        </template>
    </div>
</template>

<script>
    import Resguardante from "./../Resguardantes/components/ResguardanteR"
    import axios from "axios";

    export default {
        data(){
            return {
                isLoading: true,
                carousel: 0,
                autorizacion: {},
                $: window.$
            }
        },
        methods:{
            confirmAuth(){
                const h = this.$createElement;
                this.$msgbox({
                title: 'Autorización',
                message: h('p', null, [
                    h('p', null, '¿Deseas autorizar esta transferencia?'),
                    h('p', null, 'Al autorizar una transferencia aceptas los Términos y Condiciones'),
                    h('a', { attrs: {href: '/terminos-conficiones'} }, 'Ver Términos y Condiciones')
                ]),
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: 'Delete completed'
                    });
                }).catch(() => {});
            },
            confirmReject(){
                this.$confirm('¿Deseas rechazar esta transferencia?', '', {
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    type: 'error'
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: 'Delete completed'
                    });
                }).catch(() => {});
            },
            getInfo(ID){

                axios.post(this.$root.URL+'/autorizacion.php',
                {
                    "ID": ID,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.autorizacion = response.data.Autorizacion
                        this.isLoading = false
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });

                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/autorizacion.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        "ID": ID,
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.autorizacion = response.Autorizacion
                            this.isLoading = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            }
        },
        mounted(){
            let ID = this.$route.params.ID
            this.getInfo(ID)
        },
        components: {
            Resguardante,
        }
    }
</script>