<template>
    <el-col v-masonry-tile style="flex-shrink: 0; margin-bottom: 10px;" :span="span">
        <el-card @click.native="details" :body-style="{ padding: '0px' }">
            <img style="width: 50%; height: 70px; position: relative; left: 25%;" v-if="activo.Imagenes != []" :src="activo.Imagenes[0]" class="image">
            <div style="padding: 14px; font-size: 15px;">
                <p><b>{{ activo.NoActivo }}</b></p>
                <p style="font-size: 0.9em; color: grey;">{{ activo.Descripcion }}</p>
            </div>
        </el-card>
    </el-col>
</template>

<script>
    export default {
        props: {
            activo: { require: true, type: [Object, Array] },
            flat: { required: false, type: Boolean, default : false },
            span: { required: false, type: Number, default: 11, }
        },
        methods: {
            details(){
                if(!this.flat){
                    this.$router.push(`/activo/${this.activo.NoActivo}`)
                }
            }
        }
    }
</script>