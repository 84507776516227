<template>
    <el-dialog title="" :visible.sync="view" fullscreen :footer-hide="true" @close="restart">
        <h2 class="section-title">Búsqueda de activos</h2>
        <Row>
            <Col span="21">
                <el-input size="small" style="width: 100%" placeholder="Número o descripción" v-model="query">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </Col>
            <Col span="3" style="display: flex; justify-content: center;">
                <iButton @click="qrActivate=!qrActivate" type="text" shape="circle" icon="md-qr-scanner"/>
            </Col>
        </Row>
        <div :class="{'empty': results.length == 0 && !qrActivate}" class="results-grid">
            <template v-if="results.length > 0 &&  !qrActivate">
                <Result v-for="result, i in results" :key="i" :activo="result"/>
            </template>
            <template v-if="qrActivate">
                <QRScanner v-show="qrActivate" @result="qrResult" @close="qrClose" />  
            </template>
            <template v-if="isLoading">
                <spinnerS/>
            </template>
            <template v-if="results.length == 0 && !qrActivate && !isLoading">
                <Icon style="margin-bottom: 10px" size="40" type="md-search" />
                <h5 style="font-weight: normal;" class="center-text">Busca activos por su <b>número</b> o por su <b>descripción</b></h5>
                <h5 style="font-weight: normal;" class="center-text">También puedes escanear el código QR del activo</h5>
            </template>
        </div>
    </el-dialog>
</template>

<script>
    import Result from "./components/ResultS"
    import QRScanner from "./../QR/ScannerQ"
    import axios from "axios"

    export default {
        data(){
            return {
                view: false,
                query: "",
                results: [],
                isLoading: false,
                qrActivate: false,
                $: window.$,
                EventBus: window.EventBus,
            }
        },
        watch:{
            query(){
                this.makeSearch()
            }
        },
        methods: {
            restart(){
                this.qrActivate = false
            },
            qrResult(result){
                this.qrActivate = false
                this.isLoading = true
                this.findActivo(result)
            },
            qrClose(){
                this.isLoading = false
                this.qrActivate = false
            },
            findActivo(NoActivo){

                axios.post(this.$root.URL+'/activo.php',
                {
                    "NoActivo": NoActivo,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.EventBus.$emit("toggle-loading", true)
                        this.isLoading = false
                        this.$router.push(`/activo/${NoActivo}`)
                    }
                    else {
                        this.isLoading = false
                        this.$Message.error({
                            content: response.Mensaje,
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.isLoading = false
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });
                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/activo.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        "NoActivo": NoActivo,
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.EventBus.$emit("toggle-loading", true)
                            this.isLoading = false
                            this.$router.push(`/activo/${NoActivo}`)
                        }
                        else {
                            this.isLoading = false
                            this.$Message.error({
                                content: response.Mensaje,
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.isLoading = false
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            },
            search(){
                axios.post(this.$root.URL+'/search.php',
                {
                    "query": this.query,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.results = response.data.Resultados
                        this.isLoading = false
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });

                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/search.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        "query": this.query,
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.results = response.Resultados
                            this.isLoading = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            },
            makeSearch(){
                let delay = 500

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {
                    this.isLoading = true
                    this.search()
                }, delay)
            }
        },
        mounted(){
            this.EventBus.$on("search-view", (value) => {
                this.view = value
            })
        },
        components: {
            Result,
            QRScanner,
        }
    }
</script>

<style lang="sass">
    .results-grid
        height: 70vh
        margin-top: 15px
        // background-color: #ececec
        &.empty
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            padding: 20px
</style>