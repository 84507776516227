<template>
    <el-dialog title="Transferir Activo" :visible.sync="view" fullscreen :show-close="false">
        <h3>No. Activo {{ activo.NoActivo }}</h3>
        <p>{{ activo.Descripcion }}</p>
        <br>
        <p style="margin-bottom: 5px; font-size: 0.9em;">Este activo es resguardado actualmente por</p>
        <Resguardante :user="activo.Resguardante" :showTags="false"/>
        <el-form :model="assign" :rules="rulesElement" ref="autocomplete" label-width="120px" class="demo-ruleForm">
            <h3>Transferir a</h3>
             <el-form-item prop="user">
                <el-autocomplete style="width: 100%;" v-model="assign.user" :fetch-suggestions="querySearch" placeholder="Nombre del Empleado" @select="handleSelect">
                    <template slot-scope="{ item }">
                        <div style="font-weight: bold; line-height: 1.5;">{{ item.name }}</div>
                        <div style="font-size: 0.9em; color: grey; line-height: 1.5; margin-bottom: 5px;">{{ item.departamento }}</div>
                    </template>    
                </el-autocomplete>
             </el-form-item>
        </el-form>
        <Form ref="assignForm" :model="assign" :rules="ruleInline">
            <FormItem prop="reason">
                <h3>Motivo de transferencia</h3>
                <RadioGroup v-model="assign.reason" vertical>
                    <Radio label="Garantia">
                        <span>Garantía</span>
                    </Radio>
                    <Radio label="No necesario en la plaza">
                        <span>No necesario en la plaza</span>
                    </Radio>
                    <Radio label="Descompuesto">
                        <span>Descompuesto</span>
                    </Radio>
                    <Radio label="Otro">
                        <span>Otro</span>
                    </Radio>
                </RadioGroup>
            </FormItem>
            <FormItem v-if="assign.reason == 'Garantia'" prop="service">
                <Input size="large" v-model="assign.service" autofocus placeholder="Número de servicio" />
            </FormItem>
        </Form>
        <span slot="footer" class="dialog-footer">
            <el-button :disabled="loading" size="small" @click="view = false">Cancelar</el-button>
            <el-button :loading="loading" size="small" @click="transfer" type="primary">Transferir</el-button>
        </span>
    </el-dialog>
</template>

<script>
    /* eslint-disable no-console */
    import Resguardante from "./../../../Resguardantes/components/ResguardanteR"

    export default {
        data(){
            return {
                view: false,
                activo: {},
                loading: false,
                assign: {
                    user: "",
                    reason: "",
                    service: "",
                },
                ruleInline: {
                    reason: [
                        { required: true, message: 'Motivo es requerido', trigger: 'change' },
                    ],
                    service: [
                        { required: true, message: 'No. de servicio es requerido', trigger: 'change' },
                    ]
                },
                rulesElement: {
                    user: [
                        { required: true, message: 'Nombre del usuario es requerido', trigger: 'change' }
                    ]
                },
                EventBus: window.EventBus
            }
        },
        methods: {
            handleSelect(item){
                this.assign.user = item.name
                console.log(item)
            },
            querySearch(queryString, cb){
                let results = [{ name: "Juan Perez", departamento: "Contabilidad", }, { name: "Javier Rodríguez", departamento: "Sistemas" }, { name: "Juan Perez", departamento: "Contabilidad", }, { name: "Javier Rodríguez", departamento: "Sistemas" },  { name: "Juan Perez", departamento: "Contabilidad", }, { name: "Javier Rodríguez", departamento: "Sistemas" },  { name: "Juan Perez", departamento: "Contabilidad", }, { name: "Javier Rodríguez", departamento: "Sistemas" }, ]
                cb(results)
            },
            transfer(){
                let results = [false, false];

                this.$refs['autocomplete'].validate((valid) => {
                    if(valid){
                        results[0] = true
                    }else {
                        results[0] = false
                    }
                })
                
                this.$refs['assignForm'].validate((valid) => {
                    if(valid){
                        results[1] = true
                    }else {
                        results[1] = false
                    }
                })

                if(!results.includes(false)){
                    let self = this
                    this.loading = true
                    setTimeout(() => {
                        self.view = false
                        self.init()
                    }, 2000)
                }
            },
            init(){
                this.assign = {
                    user: "",
                    reason: "",
                    service: "",
                }
                this.loading = false
                this.$refs['autocomplete'].resetFields()
            }
        },
        mounted(){
            this.EventBus.$on("show-transfer-active-modal", (activo) => {
                this.activo = {
                    ...activo,
                },
                this.view = true
            })
        },
        components: {
            Resguardante,
        } 
    }
</script>