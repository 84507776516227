<template>
    <div>
        <h2 class="section-title">Estado de Activos</h2>
        <Chart/>
    </div>
</template>

<script>
    import Chart from "./components/ChartD"

    export default {
        mounted(){
            var EventBus = window.EventBus
            EventBus.$emit("toggle-loading", false)
        },
        components: {
            Chart,
        } 
    }
</script>