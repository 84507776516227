<template>
    <div>
        <Pie
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :css-classes="cssClasses"
            :width="width"
            :height="height"
            :bind="true"
        />
    </div>
</template>
<script>
    import { Pie } from "vue-chartjs/legacy";

    import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
    } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

    export default {
        name: 'GraficaPie',
        components: {
            Pie
        },
        props: {
            chartId: {
            type: String,
            default: 'pie-chart'
            },
            datasetIdKey: {
            type: String,
            default: 'label'
            },
            width: {
            type: Number,
            default: 400
            },
            height: {
            type: Number,
            default: 400
            },
            cssClasses: {
            default: '',
            type: String
            },
        },
        data() {
            return {
                chartData: {
                    labels: [
                        'Resguardados (30)',
                        'Pendientes por resguardar (20)',
                        'Sin asignación (10)',
                        'En garantía (5)',
                    ],
                    datasets: [
                    {
                        data: [30, 20, 10, 5],
                        backgroundColor: [ '#1a3180', '#ed4014', '#966B9D', '#ff9900'],
                    }
                    ]
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        position: 'bottom',
                        labels: {
                            padding: 25,
                            fontSize: 15
                        }
                    }
                },
            }
        }
    };
</script>