import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
    },
    mutations: {
        SET_USER: ( state, user ) => {
			state.user = user
		},
    },
    actions: {
        SET_USER: ({ commit }, { user, store = true }) => {
            if(store){
                var d = new Date();
                d.setTime(d.getTime() + (365*24*60*60*1000));
                var expires = "expires="+ d.toUTCString();
                document.cookie = "user" + "=" + JSON.stringify(user) + ";" + expires + ";path=/";
            }
			commit("SET_USER", user)
		},
    },
    getters: {
		user: state => state.user,
	}
})
