<template>
    <div class="section-box" @click="watchActivo">
        <h3>{{ activo.NoActivo }}</h3>
        <p>{{ activo.Descripcion }}</p>
        <slot style="margin-top: 10px;" name="content"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            activo: { required: true, type: [Object, Array] },
            watchDetailsOnClick: { required: false, type: Boolean, default: true }
        },
        methods: {
            watchActivo(){
                if(this.watchDetailsOnClick){
                    this.$router.push(`/activo/${this.activo.NoActivo}`)
                }
            }
        }
    }
</script>