<template>
    <div @click="details" class="section-box">
        <h3>{{ autorizacion.Activo.NoActivo }}</h3>
        <p>{{ autorizacion.Activo.Descripcion }}</p>
        <p>&nbsp;</p>
        <p><span style="font-weight: bold;">De: </span> {{ autorizacion.Origen.Nombre }} <span style="font-size: 0.8em; color; grey;">({{ autorizacion.Origen.Departamento }})</span></p>
        <p><span style="font-weight: bold;">Para: </span> {{ autorizacion.Destino.Nombre }} <span style="font-size: 0.8em; color; grey;">({{ autorizacion.Destino.Departamento }})</span></p>
        <p><span style="font-weight: bold;">Fecha de solicitud: </span> {{ autorizacion.FechaSolicitud | moment("DD/MMMM/YYY hh:mm") }}</p>
        <p><span style="font-weight: bold;">Motivo: </span> {{ autorizacion.Motivo }}</p>
    </div>
</template>

<script>
    export default {
        props: {
            autorizacion: { required: true, type: [Object, Array] }
        },
        methods: {
            details(){
                this.$router.push(`/autorizacion/${this.autorizacion.ID}`)
            }
        }
    }
</script>