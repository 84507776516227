<template>
    <div @click="select" ref="box" class="section-box" style="box-shadow: none; border: 1px solid #dbdfe5;">
        <h3>{{ activo.NoActivo }}</h3>
        <p>{{ activo.Descripcion }}</p>
        <!-- <p>&nbsp;</p> -->
        <template v-if="activo.Resguardante">
            <p style="margin: 5px 0; font-size: 0.9em;">Este activo es resguardado por</p>
            <Resguardante style="margin-bottom: 5px !important;" :flat="true" :user="activo.Resguardante" :show-tags="false"/>
            <p style="font-size: 0.9em;"><span style="font-weight: bold;">Fecha de resguardo: </span> 22/Agosto/2019 09:45 AM</p>
        </template>
        <template v-else>
            <Tag type="dot" color="error">Sin resguardante</Tag>
        </template>
    </div>
</template>

<script>
    import Resguardante from "./../../Resguardantes/components/ResguardanteR"

    export default {
        props: {
            activo: { required: true, type: [Object, Array] },
            selected: { required: false, type: [Object, Array] },
        },
        methods: {
            select(){
                //this.$refs.box.toggleClass("selected-box")
                const boxElement = this.$refs.box
                boxElement.classList.remove('section-box')
                boxElement.classList.add('selected-box')
                this.$emit("toggle-select-item", this.activo)
                this.$redrawVueMasonry()
            }
        },
        mounted(){
            let ai = window._.findIndex(this.selected, (s) => {
                return s.NoActivo == this.activo.NoActivo
            })

            if(ai != -1){
                //this.$refs.box.toggleClass("selected-box")
                const boxElement = this.$refs.box
                boxElement.classList.remove('section-box')
                boxElement.classList.add('selected-box')
            }
        },
        components: {
            Resguardante,
        }
    }
</script>

<style lang="sass">
    .section-box
        transition: all .2s ease-in-out
    
    .selected-box
        transform: scale(0.95)
        border-color: #4daf7c !important
        background-color: rgba(#c8f7c5, 0.3) !important
</style>