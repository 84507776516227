import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './filters'
import moment from 'moment'

import iView from 'iview';
import './iview-theme/index.less'
Vue.use(iView);

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import "./element-theme/index.scss"
Vue.use(ElementUI);

import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);

import jquery from "jquery"
window.jquery = jquery
window.$ = jquery
//const $ = window.$;


let EventBus = new Vue()
window.EventBus = EventBus

import Spinner from "./components/General/SpinnerS"
Vue.component("spinnerS", Spinner)

//Moment configuration (Dates)
window.moment = require('moment');
moment.locale("es");

Vue.use(require('vue-moment'), {
    moment,
});

var _ = require("lodash")
window._ = _
/*
// Configuración de OneSignal
window.OneSignal = window.OneSignal || [];
const OneSignal = window.OneSignal;
OneSignal.push(function() {
  OneSignal.init({
    appId: "feeaa977-8232-4fd7-802e-a3b8d2146b81",
  });
});
*/
import { VueMasonryPlugin } from 'vue-masonry'
Vue.use(VueMasonryPlugin)

import { mapGetters } from "vuex"

new Vue({
    router,
    store,
    data(){
        return {
            URL: "https://activosfijos.apicontrol.website/api",
            
            //URL: "https://activos-api.jrcode.dev",
        }
    },
    watch: {
        '$route' () {
            EventBus.$emit("toggle-drawer", false)
        }
    },
    computed: {
        ...mapGetters([
            "user",
        ])
    },
    methods: {/*
        sendNotification(players, content="", title="", subtitle=""){
            $.ajax({
                url: "https://onesignal.com/api/v1/notifications",
                method: "post",
                data: {
                    app_id: "feeaa977-8232-4fd7-802e-a3b8d2146b81",
                    include_player_ids: players,
                    contents: {
                        "en": content,
                        "es": content,
                    },
                    headings: {
                        "en": title,
                        "es": title,
                    },
                    subtitle: {
                        "en" : subtitle,
                        "es" : subtitle,
                    }  
                }
            })
        },*/
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return JSON.parse(c.substring(name.length, c.length));
                }
            }
            return "";
        },
        deleteCookie(name) {
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
    },
    mounted(){
        let user = this.getCookie("user")

        if(user != ""){
            this.$store.dispatch("SET_USER", {
                user: user,
                store: false,
            })
        }
    },
    render: h => h(App)
}).$mount('#app')
