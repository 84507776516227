<template>
    <qrcode-stream style="height: 100%" @decode="onDecode" @init="onInit">
        <div class="footer">
            <div class="divider"></div>
            <iButton @click="close" type="default" ghost shape="circle" icon="md-close-circle"/>
            <!-- <iButton @click="switchCamera" type="default" ghost shape="circle" icon="md-reverse-camera"/> -->
        </div>
        <p v-show="error.length > 0" style="font-size: 0.9em; color: red;">{{ error }}</p>
    </qrcode-stream>
</template>

<script>
    //import { QrcodeStream } from 'vue-qrcode-reader'

    export default {
        data(){
            return {
                result: "",
                error: "",
                camera: "rear",
            }
        },
        methods: {
            onDecode (result) {
                this.result = result
                this.$emit("result", result)
                // EventBus.$emit("qr-scanner-result", result)
            },
            async onInit (promise) {
                try {
                    await promise
                } 
                catch (error) {
                    if (error.name === 'NotAllowedError') {
                        this.error = "ERROR: you need to grant camera access permisson"
                    } else if (error.name === 'NotFoundError') {
                        this.error = "ERROR: no camera on this device"
                    } else if (error.name === 'NotSupportedError') {
                        this.error = "ERROR: secure context required (HTTPS, localhost)"
                    } else if (error.name === 'NotReadableError') {
                        this.error = "ERROR: is the camera already in use?"
                    } else if (error.name === 'OverconstrainedError') {
                        this.error = "ERROR: installed cameras are not suitable"
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        this.error = "ERROR: Stream API is not supported in this browser"
                    }
                }
            },
            close(){
                this.$emit("close", true)
                // EventBus.$emit("qr-scanner-close", true)
            }
        }
    }
</script>

<style lang="sass">
    .wrapper .inside 
        & .camera
            object-fit: cover !important
            border-radius: 7px !important
            height: 69vh !important
        & .overlay
            & .footer
                display: flex
                padding: 8px
                // background-color: rgba(255, 255, 255, 0.4)
                position: absolute
                bottom: 0
                right: 0
                left: 0
    
</style>