<template>
    <div>
        <h1 class="center-text">Control de Activos Fijos</h1>
        <p class="center-text">Versión 0.1</p>
        <br>
        <Form ref="loginForm" :model="login" :rules="ruleInline">
            <FormItem prop="user">
                <Input type="text" v-model="login.user" prefix="md-contact" placeholder="Usuario"/>
            </FormItem>
            <FormItem prop="password">
                <Input v-model="login.password" prefix="md-lock" placeholder="Contraseña"/>
            </FormItem>
            <FormItem>
                <Checkbox style="line-height: 1.7" v-model="terms">Acepto los <a href="#">Términos y Condiciones</a> referente al uso de Activos Fijos. </Checkbox>
            </FormItem>
            <FormItem>
                <Button :disabled="!terms" long type="primary" @click="loginHandler">Ingresar</Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data(){
            return {
                login: {
                    user: "",
                    password: "",
                },
                terms: false,
                ruleInline: {
                    user: [
                        { required: true, message: 'Usuario es requerido', trigger: 'blur' }
                    ],
                    passw: [
                        { required: true, message: 'Contraseña es requerida', trigger: 'blur' },
                    ]
                },
                OneSignal: window.OneSignal,
                EventBus: window.EventBus,
                $: window.$,
            }
        },
        methods: {
            loginHandler(){
                //let self = this
                this.$refs['loginForm'].validate((valid) => {
                    if(valid){/*
                        this.OneSignal.push(function() {
                            this.OneSignal.sendTags({
                                user_role: "user_role",
                            }).then(function(tagsSent) {
                                console.log("tagsSent");   
                                console.log(tagsSent);   
                                // Callback called when tags have finished sending
                            });

                            this.OneSignal.setExternalUserId(self.login.user)
                            .then((r) => {
                                console.log("external user id")
                                console.log(r)
                            })
                        })*/
                        this.EventBus.$emit("toggle-loading", true)

                        axios.post(this.$root.URL+'/login.php',
                        {
                            "Usuario": this.login.user,
                            "Contrasena": this.login.password,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                        .then(response => {
                            if(response.data.Codigo == 0){
                                //let user = response
                                let redirect = "/mis-activos"
                                this.$store.dispatch("SET_USER", {user: response.data.Usuario})

                                if(response.data.Usuario.Rol == "Coordinador de Activos"){
                                    redirect = "/dashboard"
                                }

                                this.$router.push(redirect)
                            }
                            else {
                                this.$Message.error({
                                    content: 'La información proporcionada no es correcta',
                                    duration: 5,
                                })
                                this.EventBus.$emit("toggle-loading", false)
                            }
                            
                        })
                        .catch(() => {
                            this.$Message.error({
                                content: 'La información proporcionada no es correcta',
                                duration: 5,
                            })
                            this.EventBus.$emit("toggle-loading", false)
                        });
                        /*
                        this.$.ajax({
                            type: 'post',
                            url: this.$root.URL+'/login.php',
                            dataType: 'json',
                            data: JSON.stringify({
                                "Usuario": this.login.user,
                                "Contrasena": this.login.password,
                            }),
                            beforeSend: function(x) {
                                if (x && x.overrideMimeType) {
                                    x.overrideMimeType("application/json");
                                }
                            },
                            success: (response) => {
                                if(response.Codigo == 0){
                                    //let user = response
                                    let redirect = "/mis-activos"
                                    this.$store.dispatch("SET_USER", {user: response.Usuario})

                                    if(response.Usuario.Rol == "Coordinador de Activos"){
                                        redirect = "/dashboard"
                                    }

                                    this.$router.push(redirect)
                                }
                                else {
                                    this.$Message.error({
                                        content: 'La información proporcionada no es correcta',
                                        duration: 5,
                                    })
                                    this.EventBus.$emit("toggle-loading", false)
                                }
                            },
                            error: () => {
                                this.$Message.error({
                                    content: 'La información proporcionada no es correcta',
                                    duration: 5,
                                })
                                this.EventBus.$emit("toggle-loading", false)
                            }
                        })*/
                    }
                    else{
                        this.$Message.error({
                            content: 'La información proporcionada no es correcta',
                            duration: 5,
                        })
                    }
                })
            }
        }
    }
</script>