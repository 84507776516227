<template>
    <div>
        <h2 class="section-title">Autorizaciones Pendientes</h2>
        <template v-if="isLoading">
            <spinnerS/>
        </template>
        <template v-else>
            <Pendiente v-for="autorizacion, i in autorizaciones" :key="i" :autorizacion="autorizacion" />
        </template>
    </div>
</template>

<script>
    import Pendiente from "./components/PendienteAP"
    import axios from "axios"

    export default {
        data(){
            return {
                isLoading: true,
                autorizaciones: [],
                $: window.$
            }
        },
        methods: {
            getInfo(){

                axios.post(this.$root.URL+'/autorizaciones.php',
                {

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.autorizaciones = response.data.Autorizaciones
                        this.isLoading = false
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });
                /*this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/autorizaciones.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.autorizaciones = response.Autorizaciones
                            this.isLoading = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            }
        },
        mounted(){
            this.getInfo()
        },
        components: {
            Pendiente,
        }
    }
</script>