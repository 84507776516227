<template>
    <div>
        <template v-if="isLoading">
            <spinnerS/>
        </template>
        <template v-else>
            <Row type="flex" style="position: relative; left: 43%;">
                <Col span="10">
                    <el-avatar style="font-size: 24px; background-color: #1a3180; " :size="120"> {{ user.Nombre | avatarName }} </el-avatar>
                </Col>
            </Row>
            <h1 class="center-text">{{ user.Nombre | name }}</h1>
            <Row style="font-size: 16px;">
                <Col span="12">
                    <p class="center-text" style="font-weight: bold">No. Empleado</p>
                    <p class="center-text">{{ user.NoEmpleado }}</p>
                </Col>
                <Col span="12">
                    <p class="center-text" style="font-weight: bold">Departamento</p>
                    <p class="center-text">{{ user.Departamento }}</p>
                </Col>
            </Row>
            <p>&nbsp;</p>
            <Row type="flex" justify="center" align="middle" style="font-size: 16px;">
                <Col span="12">
                    <p class="center-text" style="font-weight: bold">{{ user.Activos.Total | quantity }} activos totales</p>
                </Col>
            </Row>
            <p>&nbsp;</p>
            <el-tabs class="tags" type="card" :stretch="true">
                <el-tab-pane>
                    <span class="tag-type" slot="label">
                        <span class="count">{{ user.Activos.Aceptados.length | quantity }} </span> Aceptados
                    </span>
                    
                    <Activo v-for="activo in user.Activos.Aceptados" :key="'actv_a_'+activo.NoActivo" :activo="activo" />

                    <template v-if="user.Activos.Aceptados.length == 0">
                        <Alert type="warning">{{ user.Nombre | name }} no tiene activos aceptados</Alert>  
                    </template>
                </el-tab-pane>

                <el-tab-pane>
                    <span class="tag-type" slot="label">
                        <span class="count">{{ user.Activos.Pendientes.length | quantity }} </span> Pendientes
                    </span>

                    <Activo v-for="activo in user.Activos.Pendientes" :key="'actv_p_'+activo.NoActivo" :activo="activo" />

                    <template v-if="user.Activos.Pendientes.length == 0">
                        <Alert type="warning">{{ user.Nombre | name }} no tiene activos pendientes</Alert>  
                    </template>
                </el-tab-pane>

                <el-tab-pane>
                    <span class="tag-type" slot="label">
                        <span class="count">{{ user.Activos.EnGarantia.length | quantity }} </span> En garantía
                    </span>
                    
                    <Activo v-for="activo in user.Activos.EnGarantia" :key="'actv_g_'+activo.NoActivo" :activo="activo" />

                    <template v-if="user.Activos.EnGarantia.length == 0">
                        <Alert type="warning">{{ user.Nombre | name }} no tiene activos en garantía</Alert>  
                    </template>
                </el-tab-pane>
            </el-tabs>
        </template>
    </div>
</template>

<script>
    import Activo from "./../MisActivos/component/ActivoM"
    import axios from "axios"

    export default {
        data(){
            return {
                isLoading: true,
                user: [],
                $: window.$
            }
        },
        methods: {
            getInfo(NoEmpleado){

                axios.post(this.$root.URL+'/resguardante.php',
                {
                    "NoEmpleado": NoEmpleado,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.user = response.data.Resguardante
                        this.isLoading = false
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });

                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/resguardante.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        "NoEmpleado": NoEmpleado,
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.user = response.Resguardante
                            this.isLoading = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            }
        },
        mounted(){
            let NoEmpleado = this.$route.params.NoEmpleado
            this.getInfo(NoEmpleado)
        },
        components: {
            Activo,
        }
    }
</script>

<style lang="sass">
    .tag-type
        display: flex
        flex-direction: column
        line-height: 1
        font-size: 20px
        & .count
            font-size: 22px
            margin-bottom: 3px
    
    .tags.el-tabs
        & .el-tabs__item
            color: grey
            padding: 10px 20px !important
            line-height: 60px !important
            height: 60px !important
            &.is-active
                background-color: #1a3180
                color: white !important
                border-radius: 5px 0 0 5px
    
    .tags.el-tabs
        & .el-tabs__item.is-active
            &[aria-controls="pane-1"]
                background-color: #ed4014
                border-radius: 0 !important
            &[aria-controls="pane-2"]
                background-color: #ff9900
                border-radius: 0 5px 5px 0
</style>