<template>
    <el-dialog title="Modificar Activo" :visible.sync="view" fullscreen :show-close="false">
        <div>
            <h3 style="margin-bottom: 5px">Imágenes</h3>
            <el-button @click="openCamera" size="small" type="primary" icon="el-icon-camera-solid">Tomar fotografía</el-button>
            <input style="display: none" ref="inputCamera" type="file" accept="image/*" capture="camera">
            <p>&nbsp;</p>
            <el-upload class="upload-demo" action="#" :on-remove="handleRemove" :file-list="fileList" list-type="picture-card">
            </el-upload>
            <p>&nbsp;</p>
            <Form :model="obs">
                <FormItem label="">
                    <h3>Observaciones</h3>
                    <Input type="textarea" :autosize="true" v-model="obs.text"/>
                </FormItem>
            </Form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button :disabled="loading" size="small" @click="view = false">Cancelar</el-button>
            <el-button :loading="loading" size="small" @click="modify" type="primary">Modificar</el-button>
        </span>
    </el-dialog>
</template>

<script>
    /* eslint-disable no-console */
    export default {
        data(){
            return {
                view: false,
                activo: {},
                loading: false,
                obs: {
                    text: "",
                },
                fileList: [],
                EventBus: window.EventBus,
                lod: window._
            }
        },
        methods: {
            modify(){
                
            },
            openCamera(){
                this.$refs.inputCamera.click()
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handlePreview(file) {
                console.log(file);
            }
        },
        mounted(){
            this.EventBus.$on("show-modify-active-modal", (activo) => {
                this.activo = {
                    ...activo,
                }

                this.fileList = []
                this.lod.each(activo.Imagenes, (i) => {
                    this.fileList.push({
                        url: i,
                    })
                })

                this.obs.text = activo.Observaciones
                this.view = true
            })
        },
    }
</script>