<template>
    <div>
        <h2 class="section-title">Control de Garantías</h2>
        <template v-if="isLoading">
            <spinnerS/>
        </template>
        <template v-else>
            <el-tabs v-model="activeName" stretch>
                <el-tab-pane label="Pendiente" name="pending">
                    <span slot="label">
                        <Badge :count="garantias.Pendientes.length" type="primary"/> Pendientes
                    </span>

                    <Activo v-for="activo, i in garantias.Pendientes" :key="i" :activo="activo.Activo" :watchDetailsOnClick="false">
                        <template slot="content">
                            <el-input style="margin: 5px 0" placeholder="Proveedor"/>
                            <el-input placeholder="No. de Servicio"/>
                            <div style="margin-top: 10px; display: flex; justify-content: flex-end;">
                                <iButton @click="watchDetails(activo.Activo)" style="margin: 0 7px;" type="text">Ver detalles</iButton>
                                <iButton type="primary">Envíar a garantía</iButton>
                            </div>
                        </template>
                    </Activo>
                    <Alert v-if="garantias.Pendientes.length == 0" type="warning">No hay activos pendientes</Alert>
                </el-tab-pane>

                <el-tab-pane label="En garantía" name="on">
                    <span slot="label">
                        <Badge :count="garantias.EnGarantia.length" type="primary"/> En garantía
                    </span>

                    <Activo v-for="activo, i in garantias.EnGarantia" :key="i" :activo="activo.Activo" :watchDetailsOnClick="false">
                        <template slot="content">
                            <p><span style="font-weight: bold;">Proveedor: </span> {{ activo.Proveedor }}</p>
                            <p><span style="font-weight: bold;">No. de servicio: </span> {{ activo.NoServicio }}</p>
                            <div style="margin-top: 10px; display: flex; justify-content: flex-end;">
                                <iButton @click="watchDetails(activo.Activo)" style="margin: 0 7px;" type="text">Ver detalles</iButton>
                                <iButton type="primary">Recíbir activo de Proveedor</iButton>
                            </div>
                        </template>
                    </Activo>
                    <Alert v-if="garantias.EnGarantia.length == 0" type="warning">No hay activos en garantía</Alert>
                </el-tab-pane>
            </el-tabs>
        </template>
    </div>
</template>

<script>
    import Activo from "./../MisActivos/component/ActivoM"
    import axios from "axios"

    export default {
        data(){
            return {
                garantias: [],
                isLoading: true,
                activeName: "pending",
                $: window.$,
            }
        },
        methods: {
            getInfo(){

                axios.post(this.$root.URL+'/garantias.php',
                {

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.garantias = response.data.Garantias
                        this.isLoading = false
                    }
                    else {
                        this.isLoading = false
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.isLoading = false
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });
                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/garantias.php',
                    dataType: 'json',
                    data: JSON.stringify({
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.garantias = response.Garantias
                            this.isLoading = false
                        }
                        else {
                            this.isLoading = false
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.isLoading = false
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            },
            watchDetails(activo){
                this.$router.push(`/activo/${activo.NoActivo}`);
            }
        },
        mounted(){
            this.getInfo()
        },
        components: {
            Activo,
        } 
    }
</script>