<template>
    <div>
        <h2 class="section-title">Mis resguardantes</h2>
        <template v-if="isLoading">
           <spinnerS/>
        </template>
        <template v-else>
            <Resguardante v-for="user in users" :key="user.NoEmpleado" :user="user" />
        </template>
    </div>
</template>

<script>
    import Resguardante from "./components/ResguardanteR"
    import axios from "axios"

    export default {
        data(){
            return {
                users: [],
                isLoading: true,
                $: window.$
            }
        },
        methods: {
            getItems(){
                axios.post(this.$root.URL+'/resguardantes.php',
                {

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.users = response.data.Resguardantes
                        this.isLoading = false
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });

                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/resguardantes.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.users = response.Resguardantes
                            this.isLoading = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            }
        },
        mounted(){
            this.getItems()
        },
        components: {
            Resguardante,
        } 
    }
</script>