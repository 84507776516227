<template>
    <div>
        <template v-if="selected.length > 0">
            <el-row :gutter="10" v-masonry transition-duration="0.3s" item-selector=".el-col">
                <ActivoCard v-for="select, i in selected" :key="i" :activo="select" :span="12" :flat="true"/>
            </el-row>
            <p>&nbsp;</p>
            <el-form :model="assign" :rules="rulesElement" ref="autocomplete" label-width="120px" class="demo-ruleForm">
                <h3>Transferir a</h3>
                <el-form-item prop="user">
                    <el-autocomplete style="width: 100%;" v-model="assign.user" :fetch-suggestions="querySearch" placeholder="Nombre del Empleado" @select="handleSelect">
                        <template slot-scope="{ item }">
                            <div style="font-weight: bold; line-height: 1.5;">{{ item.name }}</div>
                            <div style="font-size: 0.9em; color: grey; line-height: 1.5; margin-bottom: 5px;">{{ item.departamento }}</div>
                        </template>    
                    </el-autocomplete>
                </el-form-item>
            </el-form>
            <Form ref="assignForm" :model="assign" :rules="ruleInline">
                <FormItem prop="reason">
                    <h3>Motivo de transferencia</h3>
                    <RadioGroup v-model="assign.reason" vertical>
                        <Radio label="Garantia">
                            <span>Garantía</span>
                        </Radio>
                        <Radio label="No necesario en la plaza">
                            <span>No necesario en la plaza</span>
                        </Radio>
                        <Radio label="Descompuesto">
                            <span>Descompuesto</span>
                        </Radio>
                        <Radio label="Otro">
                            <span>Otro</span>
                        </Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem v-if="assign.reason == 'Garantia'" prop="service">
                    <Input size="large" v-model="assign.service" autofocus placeholder="Número de servicio" />
                </FormItem>
            </Form>
        </template>
        <template v-else>
            <Alert type="warning">No tienes activos seleccionados</Alert>
        </template>
    </div>
</template>

<script>
    /* eslint-disable no-console */
    import ActivoCard from "./../../../Activo/components/Card/ActivoCA"

    export default {
        props: {
            selected: { required: true, type: [Object, Array,] }
        },
        data(){
            return {
                assign: {
                    user: "",
                    reason: "",
                    service: "",
                },
                ruleInline: {
                    reason: [
                        { required: true, message: 'Motivo es requerido', trigger: 'change' },
                    ],
                    service: [
                        { required: true, message: 'No. de servicio es requerido', trigger: 'change' },
                    ]
                },
                rulesElement: {
                    user: [
                        { required: true, message: 'Nombre del usuario es requerido', trigger: 'change' }
                    ]
                }
            }
        },
        methods: {
            handleSelect(item){
                this.assign.user = item.name
                console.log(item)
            },
            querySearch(queryString, cb){
                let results = [{ name: "Juan Perez", departamento: "Contabilidad", }, { name: "Javier Rodríguez", departamento: "Sistemas" } ]
                cb(results)
            },
        },
        mounted(){
            let self = this
            setInterval(() => {
                self.$redrawVueMasonry()

            }, 1000)
        },
        components: {
            ActivoCard,
        }
    }
</script>