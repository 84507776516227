<template>
    <el-dialog title="" :visible.sync="view" fullscreen :footer-hide="true">
        <h2 style="margin-bottom: 5px; word-break: break-word;">Transferencia masiva de activos</h2>
        <el-tabs v-model="tab" stretch>
            <el-tab-pane label="Búsqueda" name="search">
                <p style="font-size: 5px">&nbsp;</p>
                <Row>
                    <Col span="18">
                        <el-input size="small" style="width: 100%" placeholder="Búsqueda por número o descripción" v-model="query">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </Col>
                    <Col span="3" style="display: flex; justify-content: center; align-items: center;">
                        <iButton @click="myActives=!myActives" type="text" shape="circle" icon="md-apps"/>
                    </Col>
                    <Col span="3" style="display: flex; justify-content: center; align-items: center;">
                        <iButton @click="qrActivate=!qrActivate" type="text" shape="circle" icon="md-qr-scanner"/>
                    </Col>
                </Row>

                <div :class="{'empty': results.length == 0 && !qrActivate && !myActives}" class="results-grid">
                    <template v-if="results.length > 0 &&  !qrActivate && !myActives">
                        <h4 style="margin-bottom: 5px">Resultados</h4>
                        <Result v-for="result, i in results" :key="i" :selected="selected" :activo="result" @toggle-select-item="itemSelected"/>
                    </template>
                    <template v-if="qrActivate">
                        <QRScanner v-show="qrActivate" @result="qrResult" @close="qrClose" />  
                    </template>
                    <template v-if="myActives">
                        <template v-if="activos.length > 0">
                            <h4 style="margin-bottom: 5px">Tus Activos</h4>
                            <Result v-for="result, i in activos" :key="i" :selected="selected" :activo="result" @toggle-select-item="itemSelected"/>
                        </template>
                        <template v-else>
                            <Alert type="warning">No tienes activos para seleccionar</Alert>
                        </template>
                    </template>
                    <template v-if="isLoading">
                        <spinnerS/>
                    </template>
                    <template v-if="results.length == 0 && !qrActivate && !isLoading && !myActives">
                        <!-- <Icon class="transferIconRotate" style="margin-bottom: 10px" size="40" type="md-search" /> -->
                        <h5 style="font-weight: normal; word-break: break-word; margin-bottom: 10px;"><Icon size="20" type="md-apps" /> para seleccionar entre <b>tus activos resguardados</b>.</h5>
                        <h5 style="font-weight: normal; word-break: break-word; margin-bottom: 10px;"><Icon size="20" type="md-qr-scanner" /> para escanear el <b>código QR</b> del activo.</h5>
                    </template>
                </div>
            </el-tab-pane>

            <el-tab-pane name="selected">
                <span slot="label">
                    <Badge :count="selected.length" type="primary"/> Seleccionados
                </span>
                <p style="font-size: 5px">&nbsp;</p>
                <SelectedGrid :selected="selected" />

            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
    import Result from "./components/ResultR"
    import QRScanner from "./../QR/ScannerQ"
    import SelectedGrid from "./components/Selected/GridS"
    import axios from "axios"

    export default {
        data(){
            return {
                tab: "search",
                view: false,
                qrActivate: false,
                isLoading: false,
                myActives: false,
                query: "",
                results: [],
                selected: [],
                collapse: "",
                activos: [],
                lod : window._,
                $ : window.$,
                EventBus: window.EventBus
            }
        },
        watch:{
            query(){
                this.makeSearch()
            }
        },
        methods: {
            qrResult(result){
                if(result != ""){
                    this.findActivo(result)
                }
            },
            qrClose(){
                this.qrActivate = false
            },
            findActivo(NoActivo){
                this.isLoading = true
                axios.post(this.$root.URL+'/activo.php',
                {
                    "NoActivo": NoActivo,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.selected.push(response.data.Activo)
                        this.$Message.success({
                            content: 'Activo agregado a la lista',
                            duration: 5,
                        })
                        this.isLoading = false
                    }
                    else {
                        this.isLoading = false
                        this.$Message.error({
                            content: response.data.Mensaje,
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.isLoading = false
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });
                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/activo.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        "NoActivo": NoActivo,
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.selected.push(response.Activo)
                            this.$Message.success({
                                content: 'Activo agregado a la lista',
                                duration: 5,
                            })
                            this.isLoading = false
                        }
                        else {
                            this.isLoading = false
                            this.$Message.error({
                                content: response.Mensaje,
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.isLoading = false
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            },
            search(){

                axios.post(this.$root.URL+'/search.php',
                {
                    "query": this.query,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.results = response.data.Resultados
                        this.isLoading = false
                        this.myActives = false
                        this.qrActivate = false
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });

                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/search.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        "query": this.query,
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.results = response.Resultados
                            this.isLoading = false
                            this.myActives = false
                            this.qrActivate = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            },
            makeSearch(){
                let delay = 500

                clearTimeout(this.timer)

                this.timer = setTimeout(() => {
                    this.isLoading = true
                    this.search()
                }, delay)
            },
            itemSelected(activo){
                let a_i = this.lod.findIndex(this.selected, (s) => {
                    return s.NoActivo == activo.NoActivo
                })

                // No existe
                if(a_i == -1){
                    this.selected.push(activo)
                    this.$Message.success({
                        content: 'Activo agregado a la lista',
                        duration: 5,
                    })
                }
                else {
                    this.selected.splice(a_i, 1)
                    this.$Message.success({
                        content: 'Activo eliminado correctamente',
                        duration: 5,
                    })
                }

            },
            getActivos(){
                axios.post(this.$root.URL+'/mis-activos.php',
                {
                    "query": this.query,
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.activos = response.data.Activos.Aceptados
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });

                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/mis-activos.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        "query": this.query,
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.activos = response.Activos.Aceptados
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            }
        },
        mounted(){
            this.EventBus.$on("transferencias-masivas-view", () => {
                this.getActivos()
                this.view = true
            })
        },
        components: {
            Result,
            QRScanner,
            SelectedGrid,
        }
    }
</script>

<style lang="sass">
    .results-grid
        height: 70vh
        margin-top: 15px
        &.empty
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            padding: 20px
</style>