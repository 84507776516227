<template>
    <div>
        <h2 class="section-title">Mis activos</h2>
        <template v-if="isLoading">
            <spinnerS/>
        </template>
        <template v-else>
            <el-tabs stretch class="tags" type="card">
                <el-tab-pane>
                    <span class="tag-type" slot="label">
                        <span class="count">{{ items.Aceptados.length | quantity }} </span> Aceptados
                    </span>

                    <Activo v-for="activo in items.Aceptados" :key="'actv_'+activo.NoActivo" :activo="activo" />
                    <Alert v-if="items.Aceptados.length == 0" type="warning">No tienes activos pendientes</Alert>
                </el-tab-pane>

                <el-tab-pane>
                    <span class="tag-type" slot="label">
                        <span class="count">{{ items.Pendientes.length | quantity }} </span> Pendientes
                    </span>

                    <Activo v-for="activo in items.Pendientes" :key="'actp_'+activo.NoActivo" :activo="activo" />
                    <Alert v-if="items.Pendientes.length == 0" type="warning">No tienes activos pendientes</Alert>
                </el-tab-pane>

                <el-tab-pane>
                    <span class="tag-type" slot="label">
                        <span class="count">{{ items.EnGarantia.length | quantity }} </span> En garantía
                    </span>

                    <Activo v-for="activo in items.EnGarantia" :key="'acteg_'+activo.NoActivo" :activo="activo" />
                    <Alert v-if="items.EnGarantia.length == 0" type="warning">No tienes activos en garantía</Alert>                
                </el-tab-pane>
            </el-tabs>
        </template>
    </div>
</template>

<script>
    /* eslint-disable no-console */
    import Activo from "./component/ActivoM"
    import axios from "axios";

    export default {
        data(){
            return {
                isLoading: true,
                items: [],
                //$: window.$,
                EventBus: window.EventBus
            }
        },
        methods: {
            getInfo(){
                axios.post(this.$root.URL+'/mis-activos.php',
                {

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        console.log(response.data.Activos.Aceptados)
                        this.items = response.data.Activos
                        this.isLoading = false
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });
                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/mis-activos.php',
                    dataType: 'json',
                    data: JSON.stringify({
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.items = response.Activos
                            this.isLoading = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            }
        },
        mounted(){
            this.getInfo();
            this.EventBus.$emit("toggle-loading", false)
        },
        components: {
            Activo,
        }
    }
</script>