<template>
    <div class="section-box">
        <h3>{{ pendiente.Activo.NoActivo }}</h3>
        <p>{{ pendiente.Activo.Descripcion }}</p>
        <p>&nbsp;</p>
        <Resguardante style="margin-bottom: 10px !important" :user="pendiente.Origen" title="Origen" :flat="true" :showTags="false">
            <template slot="bottom">
                <Row>
                    <Col span="24">
                        <p>Centro de Costo: {{ pendiente.Origen.CentroCosto }}</p>
                    </Col>
                </Row>
            </template>
        </Resguardante>
        <Resguardante :user="pendiente.Destino" title="Destino" :flat="true" :showTags="false">
            <template slot="bottom">
                <Row>
                    <Col span="24">
                        <p>Centro de Costo: {{ pendiente.Destino.CentroCosto }}</p>
                    </Col>
                </Row>
            </template>
        </Resguardante>
        <br>
        <Row>
            <Col span="6" offset="18">
                <iButton @click="deletePendiente" type="primary">Entendido</iButton>
            </Col>
        </Row>
    </div>
</template>

<script>
    import Resguardante from "./../../Resguardantes/components/ResguardanteR"

    export default {
        props: {
            pendiente: { required: true, type: [Object, Array] },
        },
        methods: {
            deletePendiente(){
                this.$emit("pendientes-centros-costo-delete-item", this.pendiente)
            }
        },
        components: {
            Resguardante,
        }
    }
</script>