<template>
    <div>
        <h2 class="section-title">Pendientes por Ajustar Centro de Costo</h2>
        <template v-if="isLoading">
            <spinnerS/>
        </template>
        <template v-else>
            <Pendiente v-for="pendiente, i in pendientes" :key="i" :pendiente="pendiente" @pendientes-centros-costo-delete-item="deleteItem" />
            <Alert v-if="pendientes.length == 0" type="warning">No tienes elementos pendientes</Alert>
        </template>
    </div>
</template>

<script>
    import Pendiente from "./components/PendienteP"
    import axios from "axios"

    export default {
        data(){
            return {
                isLoading: true,
                pendientes: [],
                $: window.$,
                lod: window._
            }
        },
        methods: {
            getInfo(){

                axios.post(this.$root.URL+'/pendientes-centros.php',
                {

                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.pendientes = response.data.Pendientes
                        this.isLoading = false
                    }
                    else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    })
                });

                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/pendientes-centros.php',
                    dataType: 'json',
                    data: JSON.stringify({
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.pendientes = response.Pendientes
                            this.isLoading = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })*/
            },
            deleteItem(item){
                let ii = this.lod.findIndex(this.pendientes, (p) => {
                    return p.Activo.NoActivo == item.Activo.NoActivo
                })

                if(ii != -1){
                    this.pendientes.splice(ii, 1)
                    this.$Message.success({
                        content: 'Elemento eliminado',
                        duration: 5,
                    })
                } 
            }
        },
        mounted(){
            this.getInfo()
        },
        components: {
            Pendiente,
        }
    }
</script>