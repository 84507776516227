import Vue from 'vue'
import Router from 'vue-router'

import multiguard from 'vue-router-multiguard';
import { auth, guest, coordinadorActivos, coordinadorGarantias, coordinadorCentrosCosto } from "./guards"

import Login from "./views/LoginL"
import Activo from "./views/ActivoA"
import Dashboard from "./views/DashboardD"
import MisActivos from "./views/MisActivos"
import Autorizacion from "./views/AutorizacionA"
import Resguardante from "./views/ResguardanteE"
import Resguardantes from "./views/ResguardantesR"
import ControlGarantias from "./views/ControlGarantias"
import PendientesCentrosCosto from "./views/PendientesCentrosCosto"
import AutorizacionesPendientes from "./views/AutorizacionesPendientes"
//import RolCatalogo from "./views/RolCatalogo"

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // Login Form
        {
            path: '/',
            name: 'index',
            component: Login,
            beforeEnter: multiguard([guest]),
        },
        // Dashboard para Coordinador de Activos
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            beforeEnter: multiguard([auth, coordinadorActivos]),
        },
        // Perfil de Resguardante
        {
            path: '/resguardante/:NoEmpleado',
            name: 'resguardante',
            component: Resguardante,
            beforeEnter: multiguard([auth]),

        },
        // Lista de Resguardantes que el usuario loggeado tiene a su cardo
        {
            path: '/resguardantes',
            name: 'resguardantes',
            component: Resguardantes,
            beforeEnter: multiguard([auth]),

        },
        // Listado de activos del usuario loggeado
        {
            path: '/mis-activos',
            name: 'mis-activos',
            component: MisActivos,
            beforeEnter: multiguard([auth]),

        },
        // Perfil del Activo
        {
            path: '/activo/:NoActivo',
            name: 'activo',
            component: Activo,
            beforeEnter: multiguard([auth]),

        },
        // Transferencias pendientes de autorizar | Coordinador de Activos
        {
            path: '/autorizaciones-pendientes',
            name: 'autorizaciones-pendientes',
            component: AutorizacionesPendientes,
            beforeEnter: multiguard([auth, coordinadorActivos]),
        },
        // Autorización de Transferencia
        {
            path: '/autorizacion/:ID',
            name: 'autorizacion',
            component: Autorizacion,
            beforeEnter: multiguard([auth, coordinadorActivos]),
        },
        // Control de garantías
        {
            path: '/control-garantias',
            name: 'control-garantias',
            component: ControlGarantias,
            beforeEnter: multiguard([auth, coordinadorGarantias]),
        },
        // Pendientes Centros de Costo
        {
            path: '/pendientes-centros-costo',
            name: 'pendientes-centros-costo',
            component: PendientesCentrosCosto,
            beforeEnter: multiguard([auth, coordinadorCentrosCosto]),
        },
        /*
        // Catalogo Roles
        {
            path: '/roles',
            name: 'roles',
            component: RolCatalogo,
            beforeEnter: multiguard([auth]),
        },*/
    ]
})

export default router