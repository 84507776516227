<template>
    <div>
        <template v-if="isLoading">
            <spinnerS/>
        </template>
        <template v-else>
            <TransferPopup/>
            <ModifyPopup/>
            <Carousel v-if="activo.Imagenes != []" v-model="carousel">
                <CarouselItem v-for="image, i in activo.Imagenes" :key="i">
                    <div class="carousel-item">
                        <el-image style="height: 100%; position: relative; left: 23%;" :src="image" fit="contain"/>
                    </div>
                </CarouselItem>
            </Carousel>
            <br>
            <h3 style="font-size: 15px;">No. Activo {{ activo.NoActivo }}</h3>
            <p style="font-size: 15px;">{{ activo.Descripcion }}</p>
            <br>
            <Row>
                <Col span="24">
                    <iButton @click="modify" style="margin: 0 2px; font-size: 14px;" icon="md-create">Modificar</iButton>
                    <iButton v-if="activo.Estado == 'Activo' && activo.Transferible" @click="transfer" style="margin: 0 2px; font-size: 14px;" type="primary" icon="md-repeat">Transferir</iButton>
                </Col>
                <Col style="padding-top: 10px;" span="24">
                    <Alert v-if="!activo.Transferible" type="error">Este activo no es transferible</Alert>  
                </Col>
            </Row>
            <Tag style="margin-bottom: 10px;" v-if="activo.Estado == 'Pendiente'" type="dot" color="error">Activo Pendiente de Autorizar</Tag>
            <Tag style="margin-bottom: 10px;" v-if="activo.Estado == 'EnGarantia'" type="dot" color="warning">Activo en Garantía</Tag>
            <br>
            <template v-if="activo.Resguardante != []">
                <p style="margin-bottom: 5px; font-size: 15px;">Este activo es resguardado por: </p>
                <Resguardante :user="activo.Resguardante" :showTags="false"/>
            </template>
            <template v-else>
                 <p style="margin-bottom: 5px">Este activo no es resguardado por nadie actualmente.</p>
            </template>
            <template v-if="activo.ActivosHijos && Object.keys(activo.ActivosHijos).length > 0">
                <br>
                <p style="font-size: 15px;">Activos relacionados</p>
                <el-row style="display: flex; overflow-x: auto; padding: 10px 0;" :gutter="20">
                    <Hijo v-for="activo, i in activo.ActivosHijos" :key="'ahi_'+i" :activo="activo" />
                </el-row>
            </template>
            <template v-if="activo.ActivoPadre && Object.keys(activo.ActivoPadre).length > 0">
                <br>
                <p style="margin-bottom: 10px; font-size: 15px;">Activo padre</p>
                <el-row :gutter="20">
                    <Hijo :activo="activo.ActivoPadre" />
                </el-row>
            </template>
            <br>
            <Row style="font-size: 15px;">
                <Col span="12">
                <p style="font-weight: bold; margin-bottom: 3px;">No. Serie</p>
                    <p>{{ activo.NoSerie }}</p>
                </Col>
                <Col span="12">
                <p style="font-weight: bold; margin-bottom: 3px;">Costo de Adquisición</p>
                    <p>{{ activo.CostoAdquisicion | price }}</p>
                </Col>
                <p>&nbsp;</p>
                <Col span="12">
                    <p style="font-weight: bold; margin-bottom: 3px;">Fecha de Resguardo</p>
                    <p>{{ activo.FechaResguardo | moment("DD/MMMM/YYYY") }}</p>
                </Col>
                <Col span="12">
                    <p style="font-weight: bold; margin-bottom: 3px;">Fecha de Adquisición</p>
                    <p>{{ activo.FechaAdquisicion | moment("DD/MMMM/YYYY") }}</p>
                </Col>
                <p>&nbsp;</p>
                <Col span="12">
                    <p style="font-weight: bold; margin-bottom: 3px;">Centro de costos</p>
                    <p>{{ activo.CentroCosto }}</p>
                </Col>
                <Col span="12">
                    <p style="font-weight: bold; margin-bottom: 3px;">Tipo de costo</p>
                    <p>{{ activo.TipoCosto }}</p>
                </Col>
                <p>&nbsp;</p>
                <Col span="24" v-if="activo.Observaciones != ''">
                    <p style="font-weight: bold; margin-bottom: 3px;">Observaciones</p>
                    <p>{{ activo.Observaciones }}</p>
                </Col>
            </Row>
        </template>
    </div>
</template>

<script>
    import Hijo from "./components/Card/ActivoCA"
    import ModifyPopup from "./components/Modificar/PopupMA"
    import Resguardante from "./../Resguardantes/components/ResguardanteR"
    import TransferPopup from "./components/Transferir/PopupTA"
    import axios from 'axios'
//import { response } from "express"

    export default {
        data(){
            return {
                carousel: 0,
                activo: {},
                isLoading: true,
                EventBus: window.EventBus,
                $: window.$
            }
        },
        watch: {
            '$route': function(){
                this.isLoading = true
                setTimeout(() => {
                    this.getInfo(this.$route.params.NoActivo)
                    this.EventBus.$emit("search-view", false)
                    this.EventBus.$emit("toggle-loading", false)
                }, 1000)
            }
        },
        methods: {
            transfer(){
                this.EventBus.$emit("show-transfer-active-modal", this.activo)
            },
            modify(){
                this.EventBus.$emit("show-modify-active-modal", this.activo)
            },
            getInfo(NoActivo){

                axios.post(this.$root.URL+'/activo.php',
                {
                    "NoActivo": NoActivo 
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if(response.data.Codigo == 0){
                        this.activo = response.data.Activo;
                        this.isLoading = false;
                    } else {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        });
                    }
                    
                })
                .catch(() => {
                    this.$Message.error({
                        content: 'Algo salió mal',
                        duration: 10,
                    });
                });
                /*
                this.$.ajax({
                    type: 'post',
                    url: this.$root.URL+'/activo.php',
                    dataType: 'json',
                    data: JSON.stringify({
                        "NoActivo": NoActivo,
                    }),
                    beforeSend: function(x) {
                        if (x && x.overrideMimeType) {
                            x.overrideMimeType("application/json");
                        }
                    },
                    success: (response) => {
                        if(response.Codigo == 0){
                            this.activo = response.Activo
                            this.isLoading = false
                        }
                        else {
                            this.$Message.error({
                                content: 'Algo salió mal',
                                duration: 10,
                            })
                        }
                    },
                    error: () => {
                        this.$Message.error({
                            content: 'Algo salió mal',
                            duration: 10,
                        })
                    }
                })
                */
            }
        },
        mounted(){
            let NoActivo = this.$route.params.NoActivo
            this.getInfo(NoActivo)
            this.EventBus.$emit("search-view", false)
            this.EventBus.$emit("toggle-loading", false)
        },
        components: {
            Hijo,
            ModifyPopup,
            Resguardante,
            TransferPopup,
        }
    }
</script>

<style lang="sass">
    .carousel-item
        display: flex
        align-items: center
        width: 100%
        height: 45vh
        background-color: white
        & img
            width: 100%
</style>