<template>
	<div id="app">
		<Layout>
			<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#4A89DC"/>
			<Header>
				<AppHeader/>
			</Header>
			<Content :style="{padding: '0 30px'}">
				<Search/>
				<Transferencias/>
				<router-view/>
			</Content>
		</Layout>
	</div>
</template>

<script>
	import Loading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';

	import Search from "./components/Search/PopupS"
	import AppHeader from "./components/HeaderH"
	import Transferencias from "./components/TransferenciaMasiva/PopupT"

	export default {
		data(){
			return {
				isLoading: false,
				EventBus: window.EventBus
			}
		},
		mounted(){
			this.EventBus.$on("toggle-loading", (value) => {
				if(value){
					this.$Spin.show({
						render: (h) => {
							return h('div', [
								h('img', {
									'class': 'loading-logo',
									attrs: {
										'src': "/img/yourlogo.png",
									},
								}),
								h('Icon', {
									'class': 'demo-spin-icon-load',
									props: {
										type: 'ios-loading',
										size: 35
									}
								}),
							])
						}
					});
				}
				else {
					this.$Spin.hide();
				}
			})
		},
		components: {
			Search,
			Loading,
			AppHeader,
			Transferencias,
		}
	}
</script>

<style lang="sass">
	@import './iview-theme/override.sass'

	body
		background-color: #f5f7f9 !important
	
	.center-text
		text-align: center
	
	.section-title
		margin-bottom: 15px
		font-size: 24px
	
	.section-box
		padding: 10px
		border-radius: 5px
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0), 0 1px 10px 0 rgba(0, 0, 0, 0.05)
		background-color: white
		margin-bottom: 15px
		border: 1px solid #ececec
		font-size: 20px
		p
			font-size: 18px

	
	.loading-logo
		width: 150px
		margin-bottom: 15px
	
	.demo-spin-icon-load
		animation: ani-demo-spin 1s linear infinite
	
	.ivu-spin-text div
		display: flex
		flex-direction: column
	
	.transfer-users
		position: relative

	.transfer-icon
		position: absolute
		top: 40%
		left: 0
		right: 0
		// background-color: white
		display: flex
		justify-content: center
		& .icon
			width: 30px
			height: 30px
			// border-radius: 50%
			background-color: white
			display: flex
			align-items: center
			justify-content: center
			line-height: 30px
</style>