<template>
    <div>
        <div class="app-nav">
            <div v-if="user != null" class="menu-button">
                <Button @click="drawer = true" size="small" type="text" shape="circle" icon="md-menu" />
            </div>
            <img class="logo" src="/img/yourlogo.png" alt="Logo">
            <div class="divider"></div>
            <iButton class="transferIconRotate" @click="transfer" style="margin: 0 2px " v-if="user != null" type="text"
                shape="circle" icon="md-swap"/>
            <iButton v-if="user != null" @click="search" type="text" shape="circle" icon="md-search"/>
        </div>
        <Drawer placement="left" v-model="drawer">
            <div class="drawer-top">
                <img class="logo" src="/img/yourlogo.png" alt="Logo">
            </div>
            <div v-if="user != null" class="drawer-user">
                <div class="avatar">
                    <Avatar size="small" style="background-color: #1a3180">{{ user.Nombre | avatarName }}</Avatar>
                </div>
                <div class="info">
                    <p class="name">{{ user.Nombre }}</p>
                    <p class="code">{{ user.Rol }}</p>
                </div>
                <iButton @click="logout" type="text" shape="circle" icon="md-exit" />
            </div>
            <!-- Render list items -->
            <template v-if="user != null">
                <!-- <router-link v-for="item, i in items" :key="i" v-if="authorizeToList(item)" class="drawer-item" :to="item.path"> -->
                <div v-for="item in items" :key="item.path">
                    <div v-if="!item.children">
                        <router-link :to="item.path" class="drawer-item">
                            <Icon size="17" :type="item.icon" />
                            <p>{{ item.label }}</p>
                        </router-link>
                    </div>

                    <div v-if="item.children" class="menu-item" @click="isOpen = !isOpen">
                        <router-link :to="item.path" class="drawer-item">
                            <Icon size="17" :type="item.icon" />
                            <p>{{ item.label }}</p>
                            <div>
                                <Icon size="17" type="ios-arrow-down" />
                            </div>

                        </router-link>
                        <transition name="fade" apear>
                            <div v-if="isOpen">
                                <div v-for="childRoute in item.children" :key="childRoute.path">
                                    <router-link :to="childRoute.path" class="drawer-children">
                                        <Icon size="17" :type="childRoute.icon" />
                                        <p>{{ childRoute.label }}</p>
                                    </router-link>
                                </div>
                            </div>
                        </transition>


                    </div>
                </div>
                <!--
                    <router-link v-for="item, i in items" :key="i" class="drawer-item" :to="item.path">
                <Icon size="17" :type="item.icon" />
                <p>{{ item.label }}</p>
                </router-link>
                -->
            </template>
        </Drawer>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    data() {
        return {
            isOpen: false,
            drawer: false,
            items: [
                {
                    path: "/dashboard",
                    label: "Dashboard",
                    icon: "md-home",
                    roles: ["Coordinador de Activos"],
                },
                {
                    path: "/mis-activos",
                    label: "Mis Activos",
                    icon: "md-laptop",
                    roles: ["public"],
                },
                {
                    path: "/resguardantes",
                    label: "Mis Resguardantes",
                    icon: "md-person",
                    roles: ["public"],
                },
                {
                    path: "/autorizaciones-pendientes",
                    label: "Autorizaciones Pendientes",
                    icon: "md-warning",
                    roles: ["Coordinador de Activos"],
                },
                /*
                {
                    path: "/catalogos",
                    label: "Catalogos",
                    icon: "md-laptop",
                    roles: ["public"],
                    children: [
                        {
                            label: 'Usuarios',
                            icon: "md-laptop",
                            path: 'usuarios'
                        },
                        {
                            label: 'Activos',
                            icon: "md-laptop",
                            path: 'activos'
                        },
                        {
                            label: 'Roles',
                            icon: "md-laptop",
                            path: 'roles'
                        },
                        {
                            label: 'Departamentos',
                            icon: "md-laptop",
                            path: 'deptos'
                        },
                        {
                            label: 'Puestos',
                            icon: "md-laptop",
                            path: 'puestos'
                        },
                    ]
                },*/
                {
                    path: "/control-garantias",
                    label: "Control de Garantías",
                    icon: "md-ribbon",
                    roles: ["Coordinador de Garantías"],
                },
                {
                    path: "/pendientes-centros-costo",
                    label: "Pendientes por Ajustar Centro de Costo",
                    icon: "md-briefcase",
                    roles: ["Coordinador de Activos", "Coordinador de Centros de Costo"],
                },

            ],
            EventBus: window.EventBus,
            lod: window._
        }
    },
    computed: {
        ...mapGetters([
            "user",
        ])
    },
    methods: {
        authorizeToList(item) {
            let auth = false

            if (item.roles[0] == "public") {
                auth = true
            }

            let ri = this.lod.findIndex(item.roles, (r) => {
                return r == this.user.Rol;
            })

            if (ri != -1) {
                auth = true
            }

            return auth;
        },
        search() {
            this.EventBus.$emit("search-view", true)
        },
        transfer() {
            this.EventBus.$emit("transferencias-masivas-view", true)
        },
        logout() {
            this.$root.deleteCookie("user")
            this.$store.dispatch("SET_USER", { user: null, store: false })
            this.$router.push("/")
        }
    },
    mounted() {
        this.EventBus.$on("toggle-drawer", value => {
            this.drawer = value
        })
    }
}
</script>

<style lang="sass">
    .divider
        flex: 1
        
    .app-nav
        display: flex
        align-items: center
        background-color: white !important
        width: 100%
        height: 64px
        & .logo
            width: 100px
        & .menu-button
            margin-right: 5px
        & .user
            display: flex
            justify-content: center
            align-items: center
            color: #515a6e !important
            & .info
                margin-right: 8px
    .drawer-top
        padding: 10px 0
        margin-bottom: 10px
        & img
            width: 100px
    .drawer-item
        padding: 8px 0
        display: flex
        align-items: center
        cursor: pointer
        color: #515a6e !important
        & .ivu-icon
            margin-right: 15px
        & p
            font-size: 14px
    .drawer-children
        padding: 8px 0
        padding-left: 20px
        display: flex
        align-items: center
        cursor: pointer
        color: #515a6e !important
        & .ivu-icon
            margin-right: 15px
        & p
            font-size: 11px

    .drawer-user
        padding: 0 0 15px 0
        border-bottom: 1px solid #ececec
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 15px
        & p
            line-height: 1.6
            // text-align: right
        & .info
            flex: 1
            margin-left: 7px
            & .name
                font-weight: bold
                font-size: 13px
            & .code
                font-size: 12.5px
                color: grey
    
    .transferIconRotate
        transform: rotate(90deg)
</style>
