<template>
    <div @click="details" :class="{'flat' : flat}" class="section-box resguardante-list">
        <div class="avatar">
            <Avatar style="background-color: #1a3180">{{ user.Nombre | avatarName }}</Avatar>
        </div>
        <div class="info">
            <p v-if="title != ''" class="title">{{ title }}</p>
            <p class="name">{{ user.Nombre }}</p>
            <Row>
                <Col span="12">
                    <p>No. Empleado: {{ user.NoEmpleado }}</p>
                </Col>
                <Col span="12">
                    <p>{{ user.Activos.Total }} activos totales</p>
                </Col>
            </Row>
            <div v-if="showTags" class="tags">
                <Tag @click.native="details" type="dot" v-if="user.Activos.Aceptados > 0" color="#1a3180">{{ user.Activos.Aceptados }} aceptados</Tag>
                <Tag @click.native="details" type="dot" v-if="user.Activos.Pendientes > 0" color="error">{{ user.Activos.Pendientes }} pendientes</Tag>
                <Tag @click.native="details" type="dot" v-if="user.Activos.EnGarantia > 0" color="warning">{{ user.Activos.EnGarantia }} en garantía</Tag>
            </div>
            <slot name="bottom"/>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            user: { required: true, type: [Array, Object] },
            title: { required: false, type: String, default: '', },
            showTags: { required: false, type: Boolean, default: true, },
            flat: { required: false, type: Boolean, default : false },
        },
        methods: {
            details(){
                // if(!this.flat){
                    this.$router.push(`/resguardante/${this.user.NoEmpleado}`)
                // }
            }
        }
    }
</script>

<style lang="sass">
    .resguardante-list
        display: flex
        & .avatar
            padding-right: 10px
        & .info
            flex: 1
            font-size: 15px
            & .code
                font-size: 0.9em
                color: grey
                margin-right: 5px
            & .name
                font-weight: bold
                font-size: 15px
            & .title
                font-size: 0.9em
                color: grey
            & .ivu-tag
                height: 28px !important
                line-height: 28px !important
                font-size: 16px !important
                padding: 0 8px !important
        &.flat
            box-shadow: none !important
            background-color: transparent !important
            padding: 5px 0 !important
            margin-bottom: 0 !important
            border: none !important

</style>