<template>
    <Grid/>
</template>

<script>
    import Grid from "./../components/Autorizacion/GridAT"

    export default {
        components: {
            Grid,
        }
    }
</script>